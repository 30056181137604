import React from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import classNames from 'classnames';

import { ArrowButtonProps, ButtonLinkProps, ButtonVariant } from '~/components/Buttons/Types/constants';
import { buildButtonClassNames } from '~/components/Buttons/Utils/Button.utils';

export const NextJsButtonLink = <V extends ButtonVariant>(
	props: ButtonLinkProps<V> & { children?: React.ReactNode } & NextLinkProps,
) => {
	const {
		variant = ButtonVariant.Primary, className: propsClassName = '', color, isTransparent, ...restProps
	} = props;

	let direction;
	let size;
	let className = buildButtonClassNames(variant, color, isTransparent);

	if (variant === ButtonVariant.Arrow) {
		({ direction, size } = restProps as unknown as ArrowButtonProps);
		className = buildButtonClassNames(variant, color, isTransparent, direction, size);
		if (!direction || !size) {
			throw new Error('Arrow buttons must have direction and size props.');
		}
	}

	return (
		<NextLink
			{...restProps}
			className={
				classNames(className, {
					[propsClassName]: propsClassName,
				})
			}
		>
			{props.children}
		</NextLink>
	);
};
